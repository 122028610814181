import React from 'react';
import '../static/css/Home.css'; 

const Homepage = () => {

 

    return(
        <div className="home">
            <span><h1>Anna Cunningham</h1></span>

            <span><h2>Full Stack Developer</h2></span>
    
        </div>
    )
}

export default Homepage;