import React from 'react';

const Footer = () => {

    return(
        <div className="footer">
            
       
        <p>Anna Cunningham 2021</p>
        </div>
    )
}

export default Footer;